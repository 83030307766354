<template>
  <div class="page_bg">
    <!-- 顶部搜索区域 -->
    <!-- <van-sticky> -->
    <div class="search_top  flex-c-s-c">
      <div class="ser_search  flex-r-sb-c">
        <div />
        <van-icon name="arrow-left" size="0.5rem" @click="backPath" />
        <van-search v-model="searchValue" class="classify_ser" placeholder="请输入课程名称" shape="round" @search="onSearch"
                    @focus="onFocus"
        >
          <template #right-icon>
            <van-button round color="#0767AB" size="small" style="width:1.49rem;height:0.69rem;margin-top:-0.1rem"
                        @click="onSearch"
            >搜索</van-button>
            <!-- <van-image width="1.49rem" height="0.6rem" src="https://oss.xiaoyi120.com/shop2.0/public/ser_btn.png" /> -->
          </template>
        </van-search>
        <!-- <div class="classify_btn" @click="$router.push('/product/searchpro')">
            <van-button round color="#0767AB" size="small">搜索</van-button>
          </div> -->
        <div />
      </div>
    </div>
    <!-- </van-sticky> -->
    <div v-show="isSer">
      <MyscrollList ref="myList01" :list="proList" :line-type="1" @myload="proload">
        <template #default="slotProps">
          <classCard01 :info="slotProps.item" />
        </template>
      </MyscrollList>
    </div>
    <div v-show="!isSer">
      <!-- 历史查询 -->
      <div v-if="historyList.length>0" class="search_history">
        <div class="search_history_top flex-r-sb-c">
          <div class="history_top_name">搜索历史</div>
          <div class="history_top_icon" @click="clearHistory">
            <van-icon name="delete-o" />
          </div>
        </div>
        <div class="history_contant flex-r-s-s">
          <div v-for="(his,his_index) in historyList" :key="his_index" @click="HSearch(his)">
            <div class="history_message van-ellipsis">{{ his }}</div>
          </div>
        </div>
      </div>
      <!-- 热门搜索 -->
      <div class="search_hot">
        <div class="search_hot_title">热门搜索</div>
        <van-row>
          <div class="search_hot_contant">
            <van-col v-for="(hot,hot_index) in hotList" :key="hot_index" :span="22">
              <classCard01 :info="hot" />
            </van-col>
          </div>
        </van-row>
      </div>
    </div>
    <!-- loading遮罩层 -->
    <van-overlay :show="overlayShow" @click="overlayShow = false">
      <div class="overlay_loading">
        <van-loading color="#eee" size="0.6rem" type="spinner" vertical>数据正在赶来的路上...</van-loading>
      </div>
    </van-overlay>

  </div>
</template>

<script>
import './index.scss'
import { cloudroom_searchList, cloudroom_topList } from '@/api/study'
import MyscrollList from '@/components/MyscrollList'
import classCard01 from '@/components/class_card_01'
export default {
  components: { MyscrollList, classCard01 },
  data() {
    return {
      isSer: false,
      // 商品列表
      proSearch: { page: 1, limit: 10 },
      proList: [],
      listLoading: false,
      tabIndex: 1,
      searchValue: '',
      historyList: JSON.parse(localStorage.getItem('historySerClass')) || [],
      hotList: [],
      overlayShow: false
    }
  },
  created() {
    if (this.$route.query.name) {
      this.searchValue = this.$route.query.name
      this.onSearch()
    }
    this.loadHot()
  },
  methods: {
    backPath() { window.history.back() },
    changeType(type) {
      this.tabIndex = type
    },
    // 清空搜索历史
    clearHistory() {
      this.$dialog.confirm({ message: '是否清空历史查询记录' })
        .then(() => {
          localStorage.removeItem('historySerClass')
          this.historyList = []
        })
        .catch(() => { })
    },
    // 加载热门搜索
    loadHot() { cloudroom_topList({ page: 1, limit: 10 }).then(res => { this.hotList = res.data.list }) },
    // 热门、历史查询
    HSearch(val) {
      this.overlayShow = true
      this.$refs.myList01.load_status_clear()
      this.searchValue = val
      this.proSearch.page = 1
      this.proList = []
      this.onSearch()
    },
    // 存localstrong 事件
    setHistory(e) {
      for (var i = 0; i < this.historyList.length; i++) {
        if (this.historyList[i] === e) { this.historyList.splice(i, 1) }
      }
      this.historyList.unshift(this.searchValue)
      localStorage.setItem('historySerClass', JSON.stringify(this.historyList))
    },
    // 获焦事件
    onFocus() {
      this.$refs.myList01.load_status_clear()
      this.isSer = false
      this.searchValue = ''
      this.proList = []
      this.proSearch.page = 1
    },
    // 查询事件
    onSearch() {
      this.overlayShow = true
      this.$set(this.proSearch, 'keyword', this.searchValue)
      cloudroom_searchList(this.proSearch).then(res => {
        this.overlayShow = false
        this.isSer = true
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.list)
        // 存localstrong
        if (this.proSearch.page === 1) { this.setHistory(this.searchValue) }
        if (this.proSearch.page === 1 && res.data.list.length === 0) {
          this.$refs.myList01.load_empty('ser', true)
        } else {
          if (res.data.list.length < 10) { // 数据全部加载完成
            if (res.data.list.length > 0) {
              this.$refs.myList01.load_finished(true)
            }
          }
        }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.page = this.proSearch.page + 1
        this.onSearch()
        // 加载状态结束
      }, 50)()
    }

  }
}
</script>

<style>
</style>
